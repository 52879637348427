import React, { useState } from "react";
import {
  AppBar,
  Box,
  Button,
  Toolbar,
  IconButton,
  Menu,
  MenuItem,
  useMediaQuery,
} from "@mui/material";
import { Menu as MenuIcon } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";

const HeaderMain = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <AppBar
      position="sticky"
      style={{
        backgroundColor: "#FAFAFA",
        top: 0,
        zIndex: 100,
        boxShadow: "none",
      }}
    >
      <Toolbar
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <img
            src="/logo.png"
            alt="MYAH AI Logo"
            style={{ filter: "invert(100%)", maxHeight: "50px", height: "auto" }}
            onClick={() => navigate("/")}
          />
        </Box>
        {isMobile ? (
          <>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={handleMenuClick}
              style={{ color: "#4A4A4A" }} // Dark gray button
            >
              <MenuIcon />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
              PaperProps={{
                style: {
                  backgroundColor: "#2E2E2E", // Darker gray dropdown
                  color: "#FFFFFF", // White text
                },
              }}
            >
              <MenuItem
                onClick={() => {
                  navigate("/features");
                  handleMenuClose();
                }}
                sx={{ color: "#FFFFFF" }}
              >
                Features
              </MenuItem>
              <MenuItem
                onClick={() => {
                  navigate("/pricing");
                  handleMenuClose();
                }}
                sx={{ color: "#FFFFFF" }}
              >
                Pricing
              </MenuItem>
              <MenuItem
                onClick={() => {
                  navigate("/contact");
                  handleMenuClose();
                }}
                sx={{ color: "#FFFFFF" }}
              >
                Contact
              </MenuItem>
              <MenuItem
                onClick={() => {
                  navigate("/login");
                  handleMenuClose();
                }}
                sx={{ color: "#FFFFFF" }}
              >
                Log in
              </MenuItem>
              <MenuItem
                onClick={() => {
                  navigate("/signup");
                  handleMenuClose();
                }}
                sx={{ color: "#FFFFFF" }}
              >
                Sign up
              </MenuItem>
            </Menu>
          </>
        ) : (
          <>
            <Box sx={{ flexGrow: 1, display: "flex", justifyContent: "center" }}>
              <Button
                color="inherit"
                style={{
                  color: "#1D1E20",
                  fontFamily: "Inter, sans-serif",
                  fontSize: "20px",
                  letterSpacing: "-0.04em",
                  lineHeight: "129%",
                  textTransform: "none",
                  margin: "0 20px",
                }}
                onClick={() => navigate("/features")}
              >
                Features
              </Button>
              <Button
                color="inherit"
                style={{
                  color: "#1D1E20",
                  fontFamily: "Inter, sans-serif",
                  fontSize: "20px",
                  letterSpacing: "-0.04em",
                  lineHeight: "129%",
                  textTransform: "none",
                  margin: "0 20px",
                }}
                onClick={() => navigate("/pricing")}
              >
                Pricing
              </Button>
              <Button
                color="inherit"
                style={{
                  color: "#1D1E20",
                  fontFamily: "Inter, sans-serif",
                  fontSize: "20px",
                  letterSpacing: "-0.04em",
                  lineHeight: "129%",
                  textTransform: "none",
                  margin: "0 20px",
                }}
                onClick={() => navigate("/contact")}
              >
                Contact
              </Button>
            </Box>
            <Button
              variant="outlined"
              style={{
                borderColor: "#215FFF",
                color: "#215FFF",
                fontFamily: "Inter, sans-serif",
                fontSize: "20px",
                letterSpacing: "-0.04em",
                lineHeight: "129%",
                textTransform: "none",
                borderRadius: "20px",
                marginRight: "10px",
                padding: "5px 20px",
                backgroundColor: "#FFFFFF",
              }}
              onClick={() => navigate("/login")}
            >
              Log in
            </Button>
            <Button
              variant="contained"
              style={{
                backgroundColor: "#215FFF",
                color: "#FFFFFF",
                fontFamily: "Inter, sans-serif",
                fontSize: "20px",
                letterSpacing: "-0.04em",
                lineHeight: "129%",
                textTransform: "none",
                borderRadius: "20px",
                padding: "5px 20px",
              }}
              onClick={() => navigate("/signup")}
            >
              Sign up
            </Button>
          </>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default HeaderMain;
